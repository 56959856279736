import React, {useEffect} from 'react';
import {Button, Form, Icon, Input, Label} from "semantic-ui-react";
import {Controller, useForm} from "react-hook-form";
import styles from './index.module.scss'
import {IClient} from "../../../../store/cms/clients/types";
import {createClient, editClient} from "../../../../store/cms/clients/cmsClientsSlice";
import {useAppDispatch} from "../../../../hooks/hooks";

interface INewClientFormProps {
    closeForm: () => void
    editedClient: IClient | null
}

const NewClientForm: React.FC<INewClientFormProps> = ({closeForm, editedClient}) => {
    const dispatch = useAppDispatch()
    const {control, handleSubmit, errors, getValues, setValue} = useForm();

    useEffect(() => {
        if (editedClient) {
            setValue("name", editedClient.name)
            setValue("email", editedClient.email)
            setValue("description", editedClient.description)
            setValue("domains", editedClient.domains)
        }
    }, [editedClient]);

    const onCreateClient = () => {
        const payload: IClient = {
            name: getValues("name"),
            email: getValues("email"),
            isActive: true,
            description: getValues("description"),
            domains: getValues("domains"),
            users: 0,
            listeners: 0
        }
        dispatch(createClient(payload))
        closeForm()
    }

    const onUpdateClient = () => {
        const payload: IClient = {
            id: editedClient!.id,
            name: getValues("name"),
            email: getValues("email"),
            isActive: true,
            description: getValues("description"),
            domains: getValues("domains"),
            users: 0,
            listeners: 0
        }
        dispatch(editClient(payload))
        closeForm()
    }

    return (
        <div className="form-overlay">
            <Form
                onSubmit={editedClient ? handleSubmit(onUpdateClient) : handleSubmit(onCreateClient)}
                className={styles.form}
            >
                <Icon
                    onClick={closeForm}
                    color="red"
                    name="close"
                    size="large"
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label>Name</Label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter name"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.name && errors.name.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The name field is required</p>
                        </div>}
                </Form.Field>
                <Form.Field>
                    <Label>Email</Label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: true,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter email"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.email && errors.email.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The email field is required</p>
                        </div>}
                    {errors.email && errors.email.type === 'pattern' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>Email should be like example@xxx.xx</p>
                        </div>
                    }
                </Form.Field>
                <Form.Field>
                    <Label>Description</Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter description"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.description && errors.description.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The description field is required</p>
                        </div>
                    }
                </Form.Field>
                <Form.Field>
                    <Label>Domain</Label>
                    <Controller
                        name="domains"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter domain"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.domains && errors.domains.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The domain field is required</p>
                        </div>
                    }
                </Form.Field>
                <Button id="save_game" color="green" type="submit">
                    {editedClient ? "Update" : "Create"}
                </Button>
            </Form>
        </div>
    );
};

export default NewClientForm;
