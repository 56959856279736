import React from 'react';
import {Button, Icon} from "semantic-ui-react";
import {isShowCmsButton} from "../../helpers/isShowCmsButton";
import {handleLogout} from "../../helpers/logoutHandler";
import {useHistory} from "react-router-dom";
import {IUser} from "../../../../store/cms/usersNRoles/types";
import {useAppDispatch} from "../../../../hooks/hooks";

interface IScheduleTopButtonsProps {
    user: IUser
    isCrmUser: boolean | undefined
}

const ScheduleTopButtons: React.FC<IScheduleTopButtonsProps> = ({user, isCrmUser}) => {
    const history = useHistory();
    const dispatch = useAppDispatch()
    return (
        <div className="schedule__top-menu">
            <div className="schedule__username-block">{user?.name}</div>
            <Button
                className={'cms-btn'}
                id="statistic"
                secondary
                onClick={() => history.push("/statistics")}
            >
                <Icon style={{fontSize: "20px"}} name="chart bar"/>

            </Button>
            {
                isCrmUser &&
                <Button
                    className={isShowCmsButton('cms', user) ? 'cms-btn' : 'cms-btn-hidden'}
                    id="cms"
                    secondary
                    onClick={() => history.push("/cms")}
                >
                    <Icon style={{fontSize: "16px"}} name="cogs"/>
                </Button>
            }

            <Button id="logout" secondary onClick={() => handleLogout(dispatch, history)}>
                Logout
            </Button>
        </div>
    );
};

export default ScheduleTopButtons;
