import {IRole, IUserFormData} from "../../../../../store/cms/usersNRoles/types";
import {getRoleIDs} from "./getRolesId";

interface IUserPayload {
    inputData: IUserFormData;
    roles: IRole[];
    userId?: number;
    clientId: number
    is2FA: boolean
}

export const createUserPayload = ({inputData, roles, userId, clientId, is2FA}: IUserPayload) => {
    if (userId) {
        return {
            id: userId,
            roles: getRoleIDs(inputData.userRole, roles)!,
            email: inputData.userEmail,
            name: inputData.userName,
            clientId: clientId ? clientId : null,
            is2FA
        }
    }
    return {
        roles: getRoleIDs(inputData.userRole, roles)!,
        email: inputData.userEmail,
        name: inputData.userName,
        password: inputData.userPassword!,
        clientId: clientId ? clientId : null,
        is2FA
    }
}
