import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss"
import ClientCard from "./components/ClientCard";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {getClients} from "../../../store/cms/clients/cmsClientsSlice";
import {Button, Input} from "semantic-ui-react";
import NewClientForm from "./NewClientForm/NewClientForm";
import {IClient} from "../../../store/cms/clients/types";
import "./index.scss"
import {buildQueryString} from "../Darts/DartsGames/helpers/buildQueryString";
import {useDebounce} from "../../../hooks/useDebounce";

const ClientCards = () => {
    const dispatch = useAppDispatch()
    const {allClients} = useAppSelector(state => state.cmsClients)
    const [searchWord, setSearchWord] = useState("");
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [editedClient, setEditedClient] = useState<IClient | null>(null);

    useEffect(() => {
        dispatch(getClients(""))
    }, []);

    const onSetEditedClient = (client:IClient) => {
        setEditedClient(client)
        setIsOpenForm(true)
    }

    const onSearch = () => {
        dispatch(getClients(buildQueryString({search:searchWord})))
    }

    useDebounce(onSearch,searchWord,500)

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.controls}>
                <Input
                    defaultValue={""}
                    value={searchWord}
                    onChange={(e) => setSearchWord(e.target.value)}
                    icon='search'
                    placeholder='Search...'
                />
                <Button
                    onClick={() => setIsOpenForm(true)}
                    color="blue"
                    id="add_game"
                >
                    Add client
                </Button>
            </div>
            <div className={styles.cards}>
                {allClients.map(client => {
                    return (
                        <ClientCard
                            key={client.id as number}
                            setEditedClient={onSetEditedClient}
                            client={client}
                        />
                    )
                })}
            </div>
            {isOpenForm &&
                <NewClientForm
                    editedClient={editedClient}
                    closeForm={() => {
                        setIsOpenForm(false)
                        setEditedClient(null)
                    }
                }
                />
            }
        </div>
    );
};

export default ClientCards;
