import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {AuthStateType, IAuthResponse, ILoginData, ISetPassword, ISetPasswordResponse} from "./types";
import {LS_TOKEN, LS_CURRENT_USER} from "../../constants/localStorageItems";
import {API_LOGIN, API_SET_PASSWORD, API_VERSION} from "../../constants";
import {errorLogger} from "../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {auth} from "../../services/auth.api";
import {IUser} from "../cms/usersNRoles/types";
import {connect} from "../webSockets/webSocketsSlice";

const initialState: AuthStateType = {
    user: {} as IUser,
    userEmail: '',
    apiVersion: '',
    errorMessage: '',
    isFailedRequest: false,
    isShowVerifyInput: false,
    isFirstSetPassword: false
}


export const getOnStartUp = createAsyncThunk<IUser, void>(
    'auth/startUp',
    //@ts-ignore
    async (_, {dispatch, rejectWithValue}) => {
        try {
            if (localStorage.getItem(LS_CURRENT_USER)) {

                const storedUser = JSON.parse(localStorage.getItem(LS_CURRENT_USER)!);
                const currentUser: IUser = {
                    id: storedUser.id,
                    name: storedUser.name,
                    roles: storedUser.roles
                };
                dispatch(connect(currentUser.id));
                return currentUser
            }
        } catch (e) {
            toastr.error('Login start up:', `${e.response.data}`);
            return rejectWithValue(e.response.data)
        }
    }
)

export const getAuth = createAsyncThunk<IAuthResponse, ILoginData, { rejectValue: string }>(
    'auth/login',
    async (loginPayload, {rejectWithValue, dispatch}) => {
        try {
            let payload = {
                email: loginPayload.email,
                password: loginPayload.password
            } as ILoginData
            if (loginPayload.code) {
                payload = {...payload, code: loginPayload.code}
            }

            const {data} = await auth.post(API_LOGIN, payload);

            if (!data.is2FA && data.token) {
                const {user, token: {token}} = data;
                localStorage.setItem(LS_TOKEN, token);
                localStorage.setItem(LS_CURRENT_USER, JSON.stringify(user));
                loginPayload.history.push('/schedule');
                dispatch(getOnStartUp());
                return data;
            } else {
                toastr.warning("IGame",`${data.message}`)
                return data
            }

        } catch (e) {
            toastr.error('Login error:', `${e.response.data}`);
            return rejectWithValue(e.response.data);
        }

    }
)

export const setChangePassword = createAsyncThunk<ISetPasswordResponse, ISetPassword, { rejectValue: string }>(
    'auth/set-password',
    async (loginPayload, {rejectWithValue}) => {
        try {
            let payload = {
                email: loginPayload.email,
                password: loginPayload.password,
                codeFromEmail: loginPayload.codeFromEmail

            } as ISetPassword

            const {data} = await auth.post(API_SET_PASSWORD, payload);

            if (!data.isOk) {
                toastr.error("IGame", `${data.message}`)
            }
            if (data.isOk) {
                toastr.success("IGame", `${data.message}`)
                loginPayload.history.push("/login")
            }
            return data

        } catch (e) {
            toastr.error('Login error:', `${e.response.data}`);
            return rejectWithValue(e.response.data);
        }

    }
)

export const getVersion = createAsyncThunk(
    'auth/version',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await auth.get(API_VERSION);
            return data;
        } catch (error) {
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Login-version: ${error.name}, ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.message)
        }
    }
)


export const authSlice = createSlice({
    name: 'auth',
    reducers: {
        clearLoginState:(state) => {
            state.isShowVerifyInput = false
            state.isFirstSetPassword = false
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAuth.pending, (state: AuthStateType) => {
                state.errorMessage = "";
            })
            .addCase(getAuth.fulfilled, (state: AuthStateType, action) => {
                if (action.payload.is2FA) {
                    state.isShowVerifyInput = true
                    state.userEmail = action.meta.arg.email;
                    state.user = action.payload.user;
                }
                if (action.payload.isFirstSet) {
                    action.meta.arg.history.push("/password-issues")
                } else {
                    state.userEmail = action.meta.arg.email;
                    state.user = action.payload.user;
                }
            })
            .addCase(getAuth.rejected, (state: AuthStateType, action) => {
                state.errorMessage = action.payload;
            })
            .addCase(setChangePassword.fulfilled, (state: AuthStateType, action) => {
                if (action.payload.isFirstSet) {
                    state.isFirstSetPassword = action.payload.isFirstSet
                }
            })
            .addCase(getVersion.fulfilled, (state: AuthStateType, action) => {
                state.apiVersion = action.payload;
            })
            .addCase(getOnStartUp.fulfilled, (state: AuthStateType, action) => {
                state.user = action.payload!;
            })
    }
})
export const {clearLoginState} = authSlice.actions
export default authSlice.reducer;



