import React, { SyntheticEvent, useEffect, useState } from "react";
import { Accordion, Container } from "semantic-ui-react";
import { groupBy, sequentialNumbers } from "../../../../helpers/map.helper";
import {ILiveScoreEvents} from "../../../../store/pages/liveScoreInfo/types";
import {useLocationPanels} from "./hooks/useLocationPanels";
import "./styles.scss";

interface IDartMatchListProps {
  matches: ILiveScoreEvents[];
  onSelect: (eventId: number, isAdc: boolean) => void;
}

const DartMatchList: React.FC<IDartMatchListProps> = ({
  matches,
  onSelect,
}) => {
  const [activeIndexes, setActiveIndexes] = useState([] as number[]);
  const [matchess, setMatchess] = useState([] as ILiveScoreEvents[]);

  useEffect(() => {
    if (matches) {
      setMatchess(matches);
    }
  }, [matches]);


  const groupedByLocationMatches = groupBy<string, ILiveScoreEvents>(
      matchess,
      (match) => match.location ? match.location : ''
  );
  const locationPanels = useLocationPanels({onSelect, groupedByLocationMatches})

  useEffect(() => {
    setActiveIndexes(sequentialNumbers(locationPanels.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  const handleLocationClick = (e: SyntheticEvent, itemProps: any) => {
    const { index } = itemProps;
    const newActiveIndexes =
      activeIndexes.indexOf(index) !== -1
        ? activeIndexes.filter((i) => i !== index)
        : [...activeIndexes, index];

    setActiveIndexes(newActiveIndexes);
  };

  return (
    <Container>
      {matches ? (
        <Accordion
          panels={locationPanels}
          exclusive={false}
          activeIndex={activeIndexes}
          onTitleClick={handleLocationClick}
        />
      ) : (
        <div style={{ color: "white", fontSize: 16, textAlign: "center" }}>
          No one match in this date
        </div>
      )}
    </Container>
  );
};

export default  DartMatchList ;
