import React from 'react';
import {Button, Table} from "semantic-ui-react";

const ClientsInfo = () => {
    return (
        <div>
            <div className="users-btn-wrapper">
                <Button
                    className={'users-table'}
                    color="blue"
                >
                    Add client
                </Button>
            </div>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign="center">
                            Id
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                           Email
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                           Role
                        </Table.HeaderCell>

                        <Table.HeaderCell textAlign="center"/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell textAlign="center">
                            12
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                            Some name
                        </Table.Cell>
                        <Table.Cell textAlign="center">Some Description</Table.Cell>
                        <Table.Cell textAlign="center">
                            {/*{generateButtons(user)}*/}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
};

export default ClientsInfo;
