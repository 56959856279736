import {client} from "../../../services/api.service";
import fileDownload from "js-file-download";
import React from "react";

interface IGetCSVStatsProps  {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    pathName: string;
    activeSidebarEvent: number | null;

}
export const getCSVStatistics = async ({setIsLoading, pathName, activeSidebarEvent}: IGetCSVStatsProps) => {
    setIsLoading(true);
    const dartsAdc = pathName.includes("adc");
    let statisticPicture;
    if (dartsAdc) {
        statisticPicture = await client.get(`/api/darts-statistics/game-adc-picture/${activeSidebarEvent}`, {responseType: 'arraybuffer'});
        const fileName = statisticPicture.headers['content-disposition'].split("filename=")[1].split(';')[0]
        fileDownload(statisticPicture.data, fileName);
        return setIsLoading(false);
    }
    statisticPicture = await client.get(`/api/darts-statistics/game-picture/${activeSidebarEvent}`, {responseType: 'arraybuffer'});
    const fileName = statisticPicture.headers['content-disposition'].split("filename=")[1].split(';')[0]
    fileDownload(statisticPicture.data, fileName);
    setIsLoading(false);
};
