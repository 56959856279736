import {IUser, IUserPayload} from "../../../../../store/cms/usersNRoles/types";
import {createUser, editUser} from "../../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {AppDispatch} from "../../../../../store";
import {toastr} from "react-redux-toastr";

interface ISetUserHandler {
    user: IUserPayload;
    allUsers: IUser[];
    dispatch: AppDispatch;
    editMode: boolean;
    editedUser: IUser
}

export const setUserHandler = (
    {
        user,
        allUsers,
        dispatch,
        editMode,
        editedUser
    }: ISetUserHandler) => {

    const existingUser = allUsers.find((existingUser) => {
        return existingUser?.email?.trim() === user.email.trim();
    });

    if (editMode) {
        if (existingUser && existingUser.email === editedUser.email) {
            return dispatch(editUser(user))
        }
        if(!existingUser) {
            return dispatch(editUser(user))
        }
    }

    if (existingUser && editedUser.email !== existingUser.email) {
        return toastr.info('CMS', 'This e-mail is already used.');
    }
    if (existingUser && !editMode) {
        return toastr.info('CMS', 'This e-mail is already used.');
    }

    return dispatch(createUser(user))
};
