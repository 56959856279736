import React from "react";
import {Pagination, Table} from "semantic-ui-react";
import {useEffect} from "react";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    getDartsWeeks, setPageNumber,
} from "../../../../store/cms/darts/weeks/cmsWeeksSlice";
import {checkIsUserAdmin} from "../../../../hooks/checkIsUserAdmin";
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";


const DartsWeeks = () => {
    const dispatch = useAppDispatch();
    const {
        dartsWeeks,
        isLoading,
        noContent,
        pageNum,
        totalPages,
        pageSize,
    } = useAppSelector((state) => state.cmsWeeks);
    const {user} = useAppSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getDartsWeeks(buildQueryString({pageNum,pageSize})));
    }, []);

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum:activePage,pageSize}
        dispatch(getDartsWeeks(buildQueryString(params)));
    }

    return (
        <div>
            {noContent ? (
                <div className="schedule-info-block">No weeks</div>
            ) : (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {checkIsUserAdmin(user) &&
                                <Table.HeaderCell textAlign="center">Id</Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">Number</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Date start</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Date end</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {isLoading && <DimmerLoader/>}
                        {dartsWeeks.map((week) => {
                            return (
                                <Table.Row>
                                    {checkIsUserAdmin(user) &&
                                        <Table.Cell textAlign="center">{week.id}</Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">{week.number}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {week.dateStart.slice(0, 10)}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {week.dateEnd.slice(0, 10)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {!noContent && (
                <Pagination
                    floated="right"
                    size="mini"
                    activePage={pageNum}
                    totalPages={totalPages}
                    onPageChange={(e, {activePage}) => onChangePager(activePage)}
                />
            )}
        </div>
    );
};

export default DartsWeeks;
