import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {API_DARTS_WEEKS} from "../../../../constants";
import {DartsWeeks, DartsWeeksData} from "./types";

const initialState: DartsWeeks = {
    dartsWeeks: [] as DartsWeeksData[],
    isLoading: true,
    isVisibleForm: false,
    editMode: false,
    editWeekId: null,
    noContent: null,
    totalItems: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
}

export interface IGetWeeksResponse {
    totalItems: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
    items: DartsWeeksData[]
}

export const getDartsWeeks = createAsyncThunk<IGetWeeksResponse, string>(
    'dartsWeeks/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_WEEKS}?${query}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts weeks.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Weeks: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const cmsWeeksSlice = createSlice({
    name: 'cmsWeeksPhases',
    reducers: {
        setPageNumber: (state, action) => {
            state.pageNum = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsWeeks.fulfilled, (state, action) => {
                state.dartsWeeks = action.payload.items;
                state.noContent = !action.payload.items.length;
                state.isLoading = false;
                state.totalPages = action.payload.totalPages
            })
            .addCase(getDartsWeeks.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export const {setPageNumber} = cmsWeeksSlice.actions
export default cmsWeeksSlice.reducer;
