import {AxiosResponse} from "axios";
import {client} from "../../../../services/api.service";
import {setGame} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {AppDispatch} from "../../../../store";
import {API_DARTS_SHOOTOUT_GAMES} from "../../../../constants";


export const createActiveShootoutGameAfterRefreshPage = async (gameId: number,dispatch:AppDispatch) => {
    try {
        //in the 9 shots uses tournamentId instead of gameId
        const resp: AxiosResponse = await client.get(`${API_DARTS_SHOOTOUT_GAMES}/${gameId}`)
        const DartsGames9Shots = resp.data;
        dispatch(setGame(DartsGames9Shots));

    } catch (e) {
        if (e instanceof Error) {
            console.log(e.message)
        }
    }
};