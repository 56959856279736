import React, {useEffect, useState} from 'react';
import {Button, Input, Pagination, Select} from 'semantic-ui-react';
import MssNewsForm from "./MssNewsForm/MssNewsForm";
import DimmerLoader from "../../../components/DImmerLoader/DimmerLoader";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    formHandler,
    getNews, setPageNum, setPageSize,
} from "../../../store/cms/modusNews/cmsModusNewsSlice";
import {renderNewsTable} from "./helpers/renderNewsTable";
import './style.scss';
import styles from "../Darts/DartsCompetitions/style.module.scss";
import {buildQueryString} from "../Darts/DartsGames/helpers/buildQueryString";


const MssNewsTable = () => {
    const dispatch = useAppDispatch();
    const {
        news,
        isOpen,
        isLoading,
        editMode,
        pageSize,
        pageNum,
        totalPages,
    } = useAppSelector((state) => state.cmsNews);
    const [searchWord, setSearchWord] = useState("");

    useEffect(() => {
        const params = {pageSize,pageNum}
        dispatch(getNews(buildQueryString(params)))
    }, [])

    const formVisibilityHandler = () => dispatch(formHandler(!isOpen));

    const pagerSelectOptions = [
        {key:5,value:5,text:"5 Items"},
        {key:10,value:10,text:"10 Items"},
        {key:15,value:15,text:"15 Items"},
    ]

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {search: e.target.value, pageNum: 1, pageSize}
        setSearchWord(e.target.value)
        dispatch(getNews(buildQueryString(params)))
    }

    const onPageNumChange =(pageNum:any) => {
        dispatch(setPageNum(pageNum))
        const params = {search: searchWord, pageNum, pageSize}
        dispatch(getNews(buildQueryString(params)))
    }

    return (
        <div>
            <div className="mss-top-controls">
                <div>
                    <Input
                        icon='search'
                        placeholder='Search...'
                        value={searchWord}
                        onChange={(e) => onSearch(e)}
                    />
                </div>
                <div>
                    <Button
                        disabled={isOpen || isLoading || editMode}
                        onClick={formVisibilityHandler}
                        content="Add news item"
                        color="green"
                    />
                </div>
            </div>
            {isLoading && news ? <DimmerLoader/> : renderNewsTable({
                news,
                isLoading,
                editMode,
                isOpen,
                dispatch
            })}

            {isOpen && <MssNewsForm/>}
            <div>
                <div className={styles.pagerInner}>
                    <div>
                        <Select onChange={(e,{value})=>dispatch(setPageSize(value))}
                            value={pageSize}
                            defaultValue={pageSize}
                            className={styles.pagerSelect}
                            options={pagerSelectOptions}
                        />
                    </div>
                    <Pagination
                        floated="right"
                        size="mini"
                        activePage={pageNum}
                        totalPages={totalPages}
                        onPageChange={(e, {activePage}) => onPageNumChange(activePage)}
                    />
                </div>
            </div>
        </div>
    );
}

export default MssNewsTable;
