import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../../../components/PrivateRoute';
import DartsGamesTable from '../Darts/DartsGames/DartsGamesTable';
import DartsGroups from '../Darts/DartsGroups/DartsGroups';
import DartsPhase from '../Darts/DartsPhase/DartsPhase';
import DartsPlayersTableDashBoard from '../Darts/DartsPlayersTable/DartsPlayersTableDashBoard';
import DartsTournaments from '../Darts/DartsTournaments/DartsTournaments';
import DartsWeeks from '../Darts/DartsWeeks/DartsTournamentWeeks';
import Sidebar from './Sidebar/Sidebar';
import DartsShootOutGames from "../Darts/DartsShootOut/DartsShootOutGames";
import DartsCompetitions from "../Darts/DartsCompetitions/DartsCompetitions";
import MssNewsTable from "../News/MssNewsTable";
import Users from "../UsersRoles/Users/Users";
import Roles from "../UsersRoles/Roles/Roles";
import DartsAdcGamesTable from "../Darts/DartsAdcGames/DartsAdcGamesTable";
import DartsService from "../Darts/DartsService/DartsService";
import ClientCards from "../ClientCards/ClientCards";
import ClientsInfo from "../ClientsInfo/ClientsInfo";
import Listeners from "../UsersRoles/Listeners/Listeners";
import './styles.scss'

const CmsPage = () => {
  const {path} = useRouteMatch();

    return (
        <div className='cms__container'>
          <Sidebar name='CMS Pages'/>
          <div className='cms-content'>
            <div className='cms-dashboard__header'>CMS Dashboard</div>
            <Switch>
              <PrivateRoute path={`${path}/darts/service`} component={DartsService}/>
              <PrivateRoute path={`${path}/darts/games`} component={DartsGamesTable}/>
              <PrivateRoute path={`${path}/darts/adc-games`} component={DartsAdcGamesTable}/>
              <PrivateRoute path={`${path}/darts/players`} component={DartsPlayersTableDashBoard}/>
              <PrivateRoute path={`${path}/clients`} component={ClientCards}/>
              <PrivateRoute path={`${path}/client-info/:id`} component={ClientsInfo}/>
              <PrivateRoute path={`${path}/darts/groups`} component={DartsGroups}/>
              <PrivateRoute path={`${path}/darts/competitions`} component={DartsCompetitions}/>
              <PrivateRoute path={`${path}/darts/weeks`} component={DartsWeeks}/>
              <PrivateRoute path={`${path}/darts/tournaments`} component={DartsTournaments}/>
              <PrivateRoute path={`${path}/darts/phase`} component={DartsPhase}/>
              <PrivateRoute path={`${path}/darts/shootout`} component={DartsShootOutGames}/>
              <PrivateRoute path={`${path}/mss/news`} component={MssNewsTable}/>
              <PrivateRoute path={`${path}/users/`} component={Users}/>
              <PrivateRoute path={`${path}/listeners`} component={Listeners}/>
              <PrivateRoute path={`${path}/roles/`} component={Roles}/>
            </Switch>
          </div>
        </div>
    )
  }


export default CmsPage;
