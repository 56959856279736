import {BestCompetitionType} from "../GameScores";

export const setCurrentThrowerStyles = (currThrower: boolean, colorType: BestCompetitionType) => {
    if (currThrower && colorType === BestCompetitionType.STANDARD) {
        return "standard"
    }

    if (currThrower && colorType === BestCompetitionType.WADC) {
        return "wadsColor"
    }
    if (currThrower && colorType === BestCompetitionType.ADC) {
        return "adcColor"
    }

    return "defaultColor"
};

export const setColorClass = (colorType: BestCompetitionType) => {
    if (colorType === BestCompetitionType.STANDARD) {
        return "standard"
    }

    if (colorType === BestCompetitionType.WADC) {
        return "wadsColor"
    }
    if (colorType === BestCompetitionType.ADC) {
        return "adcColor"
    }
    return "defaultColor"
}

export const setFontColorClass = (colorType: BestCompetitionType) => {
    if (colorType === BestCompetitionType.STANDARD) {
        return {color: "#E7BB3A"}
    }

    if (colorType === BestCompetitionType.WADC) {
        return {color: "#00aded"}
    }
    if (colorType === BestCompetitionType.ADC) {
        return {color: "#e20713"}
    }
}
