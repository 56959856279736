import {IUser, UserRoles} from "../../../store/cms/usersNRoles/types";

const useRolesAccess = (user: IUser) => {
    const isCrmUser = user?.roles?.includes(UserRoles.Admin) || user?.roles?.includes(UserRoles.CrmUser);
    const isScoringUser = user?.roles?.includes(UserRoles.Admin) || user?.roles?.includes(UserRoles.Scoring);
    const isViewerUser = user?.roles?.includes(UserRoles.Admin) || user?.roles?.includes(UserRoles.Viewer);
    const isStatisticsUser = user?.roles?.includes(UserRoles.Admin) || user?.roles?.includes(UserRoles.StatsUser);
    return {isCrmUser, isScoringUser, isViewerUser, isStatisticsUser};
};

export default useRolesAccess;
